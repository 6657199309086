@import './msbit.color.scss';

#smartadmin-root{
  min-height: unset !important;
  height: auto !important;
}

form.header-search, #activity, #speech-btn, .demo,
.login-info .fa{
  display: none !important;
}

// Header:
.fixed-header #header{
  position: fixed !important;
}

#extr-page #header.auth-header{
  position: relative !important;
  display: flex !important;
  justify-content: space-between;
 //  align-items: center;
  padding: 0 30px;
  background-color: $darkBlueBlack !important;
  color: $white;
  img.img-auth-logo{
     max-width: 83%;
     max-height: 65px;
  }
}

 //logo
 #logo-group{
  display: flex !important;
  align-items: center;
  .msbit-img-logo{
    margin-left: 20px;
    img{
      max-width: 66%;
      max-height: 58px;
     }
   }
    //bell header dropdown position
   .ajax-dropdown:after, .ajax-dropdown:before{
    left: 52% ;
   }
 }

  // for the israel flag in the language menue
  .flag-he{
    background-position: -96px -66px !important;
  }
  
   //overide for the language menue in the header
   .dropdown-menu{
     >li>div {
     display: block;
     padding: 3px 20px;
     clear: both;
     font-weight: 400;
     line-height: 1.42857143;
     color: $darkGrey;
     white-space: nowrap;
    }
    >li>div:hover, .active-div-menu {
      color: $white !important;
      outline: 0;
      background-color: $blue;
      cursor: pointer;
    }
  }
  
  .smart-style-2 .header-dropdown-list div.dropdown-toggle, .smart-style-2 .header-search>button {
    color: $lightGrey;
    cursor: pointer;
  }
  //end overide for the language menue in the header
  
  #logoutBtn{
    -moz-border-radius:2px;
    -webkit-border-radius:2px;
    border-radius:2px;cursor:pointer!important;
    display:inline-block;
    font-weight:700;
    height:30px;
    line-height:24px;
    min-width:30px;
    padding:2px;
    text-align:center;
    text-decoration:none!important;
    background-color:#f8f8f8;
    background-image:-webkit-gradient(linear,left top,left bottom,from(#f8f8f8),to(#f1f1f1));background-image:-webkit-linear-gradient(top,#f8f8f8,#f1f1f1);
    background-image:-moz-linear-gradient(top,#f8f8f8,#f1f1f1);
    background-image:-ms-linear-gradient(top,#f8f8f8,#f1f1f1);
    background-image:-o-linear-gradient(top,#f8f8f8,#f1f1f1);
    background-image:linear-gradient(top,#f8f8f8,#f1f1f1);
    border:1px solid #bfbfbf;
    font-size:17px;
    margin:10px 0 0;
    background:0 0;
    border:none!important;
    color:#C5C6CA!important;
    box-shadow:none!important
  }
  
  #logoutBtn:hover{
    color:#fff!important;
    cursor:pointer!important;
    border:1px solid #bfbfbf;
    transition:all 0s;
  }

// Main
#main{
  padding-top: 8px;
  padding-bottom: 55px !important;
}

 // Footer
 .page-footer{
  position: absolute;
  bottom: 0;
}

// Navigation
#shortcut{
display: none !important;
}
.fixed-navigation #left-panel {
  height: 100%;
  position: fixed;
}

.msbit-shortcut-nav-img{
  filter: invert(1);
  display: block;
  margin: 15px auto;
  margin-bottom: 24px;
  width: 50px;
}

.smart-style-2 nav>ul>li>a{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


 .msbit-flex-center{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
 }

 .msbit-text-black{
   color: $black !important;
 }

 .msbit-d-inline{
   display: inline !important;
 }

 .msbit-d-block{
   display: block !important;
 }
 
 .msbit-invalid-input{
  border: 1px solid $red !important;
  background-color: $transparencyRed !important;
 }

.txt-color-msbityellow{
  color: orange !important;
}

.display-flex{
  display: flex;
}

 #ribbon{
   margin-top: -37px !important;
 }

 .msbit-project-name{
   margin-left: 20px;
   color: $lightGrey;
   display: none !important;
   align-items: center;
   padding-top: 2px;
 }

 //<breadCrumb/> and <stats/>
 .msbit-crumb-state{
   padding: 0px 20px;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   .big-breadcrumbs{
     margin-right: 20px;
     h1 i.fa{
      margin-left: 4px;
      margin-right: 4px;
     }
   }
 }

 .big-breadcrumbs{
  margin-right: 20px;
  h1 i.fa{
   margin-left: 7px;
   margin-right: 7px;
  }
}

//overide for the dataTable
.dt-toolbar{
  background-color: transparent;
}
.dt-toolbar-footer{
  background-color: transparent;
  display: flex;
  justify-content: center;
  div.dataTables_info{
    display: none;
    width: 100%;
  }
  .dt-toolbar-footer>:last-child, .dt-toolbar>:last-child {
    display: flex;
    justify-content: center;
    width: 100% !important;
  }
}

// overide data table pagenation
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>span, .pagination>.active>span:focus{
  z-index: 0;
}
// overide data table buttons
.btn-group-vertical>.btn.active, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:hover, .btn-group>.btn.active, .btn-group>.btn:active, .btn-group>.btn:focus, .btn-group>.btn:hover{
  z-index: 0;
}

table>tbody>tr>td{
  vertical-align: inherit !important;
}

// add for responsive datatable a "+"/"-" button to see more/less columns
table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control, table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before, table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before  {
  top: 50%;
  left: 5px;
  height: 1em;
  width: 1em;
  margin-top: -9px;
  display: block;
  position: absolute;
  color: $white;
  border: 0.15em solid $white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em $grey;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New",Courier,monospace;
  line-height: 1em;
  content: "+";
  background-color: $darkBlueBlack;
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td.dtr-control:before, table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th.dtr-control:before {
  content: "-";
  background-color: $landRed;
}

table.dataTable>tbody>tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

table.dataTable>tbody>tr.child ul.dtr-details>li:first-child {
  padding-top: 0;
}

table.dataTable>tbody>tr.child ul.dtr-details>li {
  border: none;
  padding: 0.5em 0;
}

table.dataTable>tbody>tr.child span.dtr-title {
  display: inline-block;
  min-width: 150px;
  font-weight: bold;
}
//end "+"/"-" buttons for datatable
 
//for <Stats/> component - the graph will be display none below 991px screen
 @media screen and (max-width:991px) {
  .msbit-hidden-md{
    display: none !important;
  }
 }

 @media screen and (max-width:979px) {
  #main{
    width: auto !important;
  }
 }

 @media screen and (min-width:768px) {

 }

@media screen and (max-width:768px) {
  //language selector and the municipality-name not display in mobile
  .header-dropdown-list{
    display: none;
  }
  .msbit-project-name{
    display: none !important;
  }
  #header>:first-child, aside {
    width: 200px;
    i.fa-bell{
      font-size: 0.9em;
    }
    .ajax-dropdown:after, .ajax-dropdown:before{
      left: 47%;
    }
  }
  //loginfo navbar
   aside {
    width: 220px;
  }
  .smart-style-2 .login-info a span {
    font-size: 0.8em !important
  }
  .smart-style-2 .login-info img {
    width: 21px;
  }
  .login-info>span {
    height: auto;
    .fa-angle-down{
      font-size: 0.7em;
      vertical-align: baseline;
    }
}
  //navbar menu
 .smart-style-2 nav>ul>li>a>i  {
    margin-right: 0 !important;
    font-size:1.1em !important ;
  }
  .fa-lg{
    vertical-align:0 !important;
  }
  nav>ul>li>a b {
    right: 1px !important;
  }
}

 @media screen and (max-width:500px) {
  #logo-group{
    width: 40% !important;
    a{
      padding: 0;
    }
    .msbit-img-logo{
      margin-left: 5px;
      img{
        max-width: 60%;
        max-height: 58px;
       }
    }
    span#activity{
      text-align: start;
      margin-left: -28px;
      b.badge{
        right: -4px;
        padding: 3px 4px 3px;
      }
    }
    .ajax-dropdown:after, .ajax-dropdown:before{
      left: 47% !important;
    }
   }
 }

 @media screen and (max-width: 479px){
  #logo-group .ajax-dropdown:after, .ajax-dropdown:before{
    left: 47% !important;
   }
  .msbit-crumb-state{
    justify-content: center;
    padding: 0;

    ul#sparks{
      margin: 0 auto;
      width: 100% !important;
      display: flex !important;
      flex-wrap: wrap;
      justify-content: flex-start;
      background-color: transparent;
      padding: 20px;
      margin-top: 0;
      margin-left: 0px;
      li{
        margin-top: 10px;
        overflow: initial;
      }
      ul#sparks i:first-child{
        margin-left: -10px;
      }
      .spark-info-more-2{
        border: none;
        margin-left: -20px;
      }
    }
    ul#sparks .spark-info-more-2:first-child{
      margin-left: 0px;
    }
  }
  .big-breadcrumbs{
    display: none;
  }
}

 @media screen and (max-width:420px) {
  #logo-group{
    .ajax-dropdown:after, .ajax-dropdown:before{
      left: 35% !important;
    }
  }
 }

 @media screen and (max-width:320px) {
  #logo-group{
    .ajax-dropdown{
      width: 90%;
      left: 0;
    }
    .ajax-dropdown:after, .ajax-dropdown:before{
      left: 34% !important;
    }
  }
 }