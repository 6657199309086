$red: rgb(224, 8, 8);
$transparencyRed: rgba(202, 14, 14, 0.37);
$landRed: #953b39;

$black: black;
$transparencyBlack: rgba(0, 0, 0, 0.25);
$transparencyLevel1Black: rgba(0, 0, 0, 0.904);

$darkBlueBlack: #2C3742;
$blue: #3276b1;

$lightGrey: #C5C6CA;
$grey: #444;
$middleGrey: #8b8b8b;
$darkGrey: #333;

$white: white;